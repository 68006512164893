.st0 {
  font-family: "FootlightMTLight";
}
.st1 {
  font-size: 83.0285px;
}
.st2 {
  fill: gray;
}

svg {
  width: 60%;
  height: 60%;
  text-align: center;
  fill: #5bc1ac;
}
path#XMLID_5_ {
  fill: #f55050;
  filter: url(#blurFilter4);
}
path#XMLID_11_,
path#XMLID_2_ {
  fill: #5bc1ac;
}
.circle {
  animation: out 2s infinite ease-out;
  fill: #f55050;
}

#container {
  text-align: center;
}
.message {
  color: #f55050;
}
.message:after {
  content: "]";
}
.message:before {
  content: "[";
}

.message:after,
.message:before {
  color: #5bc1ac;
  font-size: 20px;
  -webkit-animation-name: opacity;
  -webkit-animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-name: opacity;
  animation-name: opacity;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  margin: 0 5px;
}

@-webkit-keyframes opacity {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@keyframes opacity {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@keyframes out {
  0% {
    r: 1;
    opacity: 0.9;
  }
  25% {
    r: 5;
    opacity: 0.3;
  }
  50% {
    r: 10;
    opacity: 0.2;
  }
  75% {
    r: 15;
    opacity: 0.1;
  }
  100% {
    r: 20;
    opacity: 0;
  }
}
